import { Controller } from "@hotwired/stimulus"

// Not using for now, MDN share not fully supported everywhere yet

// Connects to data-controller="share"
export default class extends Controller {
  static targets = ["result"];

connect() {
console.log( "Connected to the share controller");
// console.log(this.data.get("urlvalue"));
console.log(this.resultTarget);

}

async share(e) {
    e.preventDefault();

    const shareData = {
        title: 'title',
        body: 'body',
        url: 'url',
    };
    try {
        await navigator.share(shareData);
        this.resultTarget.textContent = "MDN shared successfuly";
    } catch (err) {
        this.resultTarget.textContent = `Error: ${err}` ;
    }
  }
}
