// src/controllers/invchart_controller.js
import { Controller } from "@hotwired/stimulus"
import Chart from 'chart.js/auto'
import FactChartDataLabels from 'chartjs-plugin-datalabels'
Chart.register(FactChartDataLabels);

export default class extends Controller {
  static targets = ["priceGraph"]

   async connect() {
    
    console.log('connected')
    
    // this.chart = new Chartkick.LineChart(this.chartTarget, this.data) 
    this.chart = new Chart(this.priceGraphTarget.getContext('2d'), {
      type: 'line',
      data: {
        labels: this.xdata,
        datasets: [
          {
          label: 'NSIL Token Price, USD',
          yAxisID: "y",
          backgroundColor: 'rgb(47, 128, 237)',
          borderColor: 'rgb(47, 128, 237)',
          data: this.ydataNsil,
          order: 1,
          hidden:false,
          fill: false,
          tension: 0.4,
          pointRadius: 0
        },{
          label: 'BTC Price, USD',
          yAxisID: "y2",
          backgroundColor: "#cc9900",
          borderColor: "#cc9900",
          data: this.ydataBtc,
          order: 2,
          hidden:false,
          fill: false,
          tension: 0.4,
          pointRadius: 0
        }],},
    
      plugins: [FactChartDataLabels],
      options: {
        responsive: true,
        maintainAspectRatio: true,
        stacked: true,
        plugins: {
          title: {
            display: false,
            color: 'white'
          },
          legend: {
            display: true,
            position: 'top',
            labels: {
              usePointStyle: false,
              boxWidth: 12,
              boxHeight: 6,
            },
           
          },
          datalabels: {
            display: false
          },
          tooltip: {
            intersect: false
          } ,
        },
        scales: {
          y: {
            ticks: {
              color: 'rgb(47, 128, 237)',
              display:true
            },
            grid: {
              display: false,
            },
            position: 'left',
          },   y2: {
            ticks: {
              color: '#cc9900',
              display:true
            },
            grid: {
              display: false,
            },
            position: 'right',
          },   
          
        }
      },
    },
  )
    
  
  } 

 
  
  

  get ydataNsil() {
    var element = document.getElementById("y_nsil")
    var ydataAttribute = element.getAttribute('y-data-metrics');
    console.log(JSON.parse(ydataAttribute))
    let ydata = JSON.parse(ydataAttribute)


    return ydata
  }
  get ydataBtc() {
    var element = document.getElementById("y_btc")
    var ydataAttribute = element.getAttribute('y-data-metrics');
    console.log(JSON.parse(ydataAttribute))
    let ydata = JSON.parse(ydataAttribute)


    return ydata
  }

  get xdata() {
    var element = document.getElementById("x_metrics")
    var xdataAttribute = element.getAttribute('x-data-metrics');
    console.log(JSON.parse(xdataAttribute))
    let xdata = JSON.parse(xdataAttribute)


    return xdata
  }
  
  customSliceWithNulls(arr, start) {
    let result = [];
    let originalLength = arr.length;

    // Calculate actual start index
    start = start < 0 ? originalLength + start : start;

    // Add nulls for negative indices
    if (start < 0) {
      for (let i = start; i < 0; i++) {
        result.push(null);
      }
      start = 0;
    }

    // Add array elements or null for out-of-bounds indices
    for (let i = start; i < originalLength; i++) {
      if (i < 0 || i >= originalLength) {
        result.push(null);
      } else {
        result.push(arr[i]);
      }
    }

    return result;
  }
}
  


  


