// app/javascript/controllers/splash_screen_controller.js
import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
    // connect() {
    //   if (!sessionStorage.getItem("hideSplashScreen")) {
    //     this.hideSplashScreen();
    //   } else {
    //     const splashScreen = document.getElementById("splash-screen");
    //     splashScreen.remove();
    //   }
    // }
  
    // hideSplashScreen() {
    //   setTimeout(() => {
    //     const splashScreen = document.getElementById("splash-screen");
    //     splashScreen.classList.add("hidden");
  
    //     // Remove the splash screen from the DOM after the fade-out effect is finished
    //     setTimeout(() => {
    //       splashScreen.remove();
    //     }, 1000); // Adjust this value to match the transition duration in the CSS
  
    //     sessionStorage.setItem("hideSplashScreen", "true");
    //   }, 2000); // Adjust the timeout value as needed (in milliseconds)
    // }
  }