import { Controller } from "@hotwired/stimulus";

'use strict';

export default class extends Controller {
    connect() {
        this.toggleInput = this.element.querySelector('#darkModeToggle');
        this.setTheme(this.getPreferredTheme());
        this.updateToggleInput();
    }

    toggle() {
        const currentTheme = document.body.getAttribute('data-bs-theme');
        const newTheme = currentTheme === 'dark' ? 'light' : 'dark';
        
        this.setTheme(newTheme);
        localStorage.setItem('dark-mode', newTheme);
        this.updateToggleInput();
    }

    getPreferredTheme() {
        const storedTheme = localStorage.getItem('dark-mode');
        if (storedTheme) return storedTheme;
        
        return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
    }

    setTheme(theme) {
        document.body.setAttribute('data-bs-theme', theme);
    }

    updateToggleInput() {
        this.toggleInput.checked = document.body.getAttribute('data-bs-theme') === 'dark';
    }
}
