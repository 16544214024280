// src/controllers/intercom_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  
  connect() {
    console.log("Intercom controller connected");
    // Only manage Intercom state if we're sure the user interacted with our custom button
    // this.manageIntercomState();
  }

  openIntercom(event) {
    event.preventDefault();
    console.log('Custom Intercom button clicked');
    localStorage.setItem('intercomOpened', 'true'); // Set flag when opened via custom button
    Intercom("showNewMessage");
  }

  manageIntercomState() {
    const intercomOpened = localStorage.getItem('intercomOpened');
    if (intercomOpened === 'true') {
      // Since the user opened the widget through the custom button, we assume it's okay
      // to leave the widget in its default state for this page load.
      localStorage.removeItem('intercomOpened'); // Clear the flag after checking
    } else {
      // If the widget wasn't opened by our custom button, don't force it to hide.
      // This allows the widget to remember its own state via Intercom's mechanisms.
      // So, we do not use Intercom('hide') here.
    }
  }
}