// src/controllers/c10chart_controller.js
import { Controller } from '@hotwired/stimulus'
import Chart from 'chart.js/auto'
import C10ChartDataLabels from 'chartjs-plugin-datalabels'
Chart.register(C10ChartDataLabels);

export default class extends Controller {
  static targets = ["c10chart"]

  connect() {
    // this.chart = new Chartkick.LineChart(this.chartTarget, this.data)   


    
    this.chart = new Chart(this.c10chartTarget.getContext('2d'), {
      type: 'doughnut',
      data: {
        labels: this.xdata,
        datasets: [{
          label: '% Weights',
          backgroundColor: this.colors,
          borderColor: 'rgb(47, 128, 237,0.1)',
          data: this.ydata,
          tooltip: {
            callbacks: {
                label: function(context) {
                    let label = context.label;
                    let value = context.formattedValue;
    
                    if (!label)
                        label = 'Unknown'
    
                    let sum = 0;
                    let dataArr = context.chart.data.datasets[0].data;
                    dataArr.map(data => {
                        sum += Number(data);
                    });
    
                    let percentage = (value * 100 / sum).toFixed(2) + '%';
                    return label + ": " + percentage;
                }
            }
        },
        }]
      },
      plugins: [C10ChartDataLabels],
      options: {
        responsive: true,
        maintainAspectRatio: true,
        stacked: false,
        plugins: {
          title: {
            display: false,
            // text: '% of Assets held',
            color: 'white'
          },
            legend: {
                display: false,
            },
            datalabels: {
              anchor: 'center',
              display: 'auto',
              formatter: function (value, context) {
                return context.chart.data.labels[
                    context.dataIndex
                ];
            },
              color: '#ffffff',
            }
        }
      },
    }) 
    
  }

  

  reportTargetConnected() {
    if (this.chart) {

      this.chart.destroy()
      this.chart = new Chart(this.c10chartTarget.getContext('2d'), {
        type: 'doughnut',
        data: {
          labels: this.xdata,
          datasets: [{
            label: '% Weights',
            backgroundColor: this.colors,
            borderColor: 'rgb(47, 128, 237,0.1)',
            data: this.ydata,
            tooltip: {
              callbacks: {
                  label: function(context) {
                      let label = context.label;
                      let value = context.formattedValue;
      
                      if (!label)
                          label = 'Unknown'
      
                      let sum = 0;
                      let dataArr = context.chart.data.datasets[0].data;
                      dataArr.map(data => {
                          sum += Number(data);
                      });
      
                      let percentage = (value * 100 / sum).toFixed(2) + '%';
                      return label + ": " + percentage;
                  }
              }
          },
          }]
        },
        plugins: [C10ChartDataLabels],
        options: {
          responsive: true,
          maintainAspectRatio: true,
          stacked: false,
          plugins: {
            title: {
              display: false,
              // text: '% of Assets held',
              color: 'white'
            },
              legend: {
                  display: false,
              },
              datalabels: {
                anchor: 'center',
                display: 'auto',
                formatter: function (value, context) {
                  return context.chart.data.labels[
                      context.dataIndex
                  ];
              },
                color: '#ffffff',
              }
          }
        },
      }) 

      this.chart.update()
    }
  }

  reportTargetDisconnected() {
    if (this.chart) {

      this.chart.destroy()
      this.chart = new Chart(this.c10chartTarget.getContext('2d'), {
        type: 'doughnut',
        data: {
          labels: this.xdata,
          datasets: [{
            label: '% Weights',
            backgroundColor: this.colors,
            borderColor: 'rgb(47, 128, 237,0.1)',
            data: this.ydata,
            tooltip: {
              callbacks: {
                  label: function(context) {
                      let label = context.label;
                      let value = context.formattedValue;
      
                      if (!label)
                          label = 'Unknown'
      
                      let sum = 0;
                      let dataArr = context.chart.data.datasets[0].data;
                      dataArr.map(data => {
                          sum += Number(data);
                      });
      
                      let percentage = (value * 100 / sum).toFixed(2) + '%';
                      return label + ": " + percentage;
                  }
              }
          },
          }]
        },
        plugins: [C10ChartDataLabels],
        options: {
          responsive: true,
          maintainAspectRatio: true,
          stacked: false,
          plugins: {
            title: {
              display: false,
              // text: '% of Assets held',
              color: 'white'
            },
              legend: {
                  display: false,
              },
              datalabels: {
                anchor: 'center',
                display: 'auto',
                formatter: function (value, context) {
                  return context.chart.data.labels[
                      context.dataIndex
                  ];
              },
                color: '#ffffff',
              }
          }
        },
      }) 

      this.chart.update()
    }
  }

  get ydata() {
    var element = document.getElementById("c10_y_metrics")
    var ydataAttribute = element.getAttribute('y-data-c10metrics');
    console.log(JSON.parse(ydataAttribute))
    let ydata = JSON.parse(ydataAttribute)


    return ydata
  }

  get xdata() {
    var element = document.getElementById("c10_x_metrics")
    var xdataAttribute = element.getAttribute('x-data-c10metrics');
    console.log(JSON.parse(xdataAttribute))
    let xdata = JSON.parse(xdataAttribute)


    return xdata
  }

  get colors() { //generates random colours and puts them in string
    var colors = [];
    for (var i = 0; i < 20; i++) {
      var letters = '0123456789ABCDEF'.split('');
      var color = '#';
      for (var x = 0; x < 6; x++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      colors.push(color);
    }
    console.log(colors)

    return colors;
  }
}

