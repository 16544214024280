import { Controller } from "@hotwired/stimulus";
import Chart from "chart.js/auto";
import InvChartDataLabels from "chartjs-plugin-datalabels";
Chart.register(InvChartDataLabels);

export default class extends Controller {
  static targets = ["invchart", "investments"];

  connect() {
    this.initChart();
    window.addEventListener("resize", this.handleResize.bind(this));
  }

  disconnect() {
    window.removeEventListener("resize", this.handleResize.bind(this));
  }

  handleResize() {
    if (this.chart) {
      this.chart.resize();
    }
  }

  async initChart() {

    this.invchartTarget.style.height = '32vh';

    if (this.chart) {
      this.chart.destroy();
    }

    const bitcoinChange = await this.yDataBitcoin(this.xdata);

    this.chart = new Chart(this.invchartTarget.getContext("2d"), {
      type: "line",
      data: {
        labels: this.xdata,
        datasets: [
          {
            label: "NSIL Token Price, USD",
            yAxisID: "y",
            backgroundColor: "rgba(161, 198, 247, 1)",
            borderColor: "rgb(47, 128, 237)",
            data: this.ydata,
            order: 1,
            hidden: false,
            fill: true,
            tension: 0.4,
            pointRadius: 0,
          },
          {
            label: "Return Since Inception, %",
            yAxisID: "TOGGLE_yaxis1",
            backgroundColor: "white",
            borderColor: "white",
            data: this.y1data,
            order: 2,
            hidden: true,
            tension: 0.4,
            pointRadius: 0,
          },
          {
            label: "BTC Change in 24h, %",
            yAxisID: "comparison",
            backgroundColor: "#cc9900",
            borderColor: "#cc9900",
            data: bitcoinChange,
            order: 4,
            fill: false,
            hidden: true,
            tension: 0.4,
            pointRadius: 0,
          },
          {
            label: "NSIL Change in 24h, %",
            yAxisID: "comparison",
            backgroundColor: "rgba(161, 198, 247, 1)",
            borderColor: "rgb(47, 128, 237)",
            data: await this.nsilPercentageChange(this.xdata),
            order: 3,
            fill: false,
            hidden: true,
            tension: 0.4,
            pointRadius: 0,
          },
          {
            label: "BTC vs NSIL, %",
            yAxisID: "comparison",
            backgroundColor: "#cc9900",
            borderColor: "#cc9900",
            data: null,
            order: 3,
            fill: false,
            hidden: true,
            tension: 0.4,
            pointRadius: 0,
          },
        ],
      },
      plugins: [InvChartDataLabels],
      options: {
        responsive: true,
        maintainAspectRatio: true,
        stacked: true,
        plugins: {
          title: {
            display: false,
            color: "white",
          },
          legend: {
            display: true,
            position: "top",
            labels: {
              usePointStyle: true,
              boxWidth: 6,
              boxHeight: 5,
              generateLabels: function (chart) {
                const labels = Chart.defaults.plugins.legend.labels.generateLabels(chart);
                return labels.filter(
                  (label) =>
                    label.text !== "NSIL Change in 24h, %" &&
                    label.text !== "BTC Change in 24h, %"
                );
              },
            },
            onClick: function (event, legendItem) {
              const y_axis_id = this.chart.data.datasets[legendItem.datasetIndex].yAxisID;

              if (y_axis_id.startsWith("TOGGLE")) {
                this.chart.data.datasets[2].hidden = true;
                this.chart.data.datasets[3].hidden = true;
                this.chart.data.datasets[4].hidden = true;
                this.chart.options.scales.comparison.ticks.display = false;

                if (this.chart.options.scales.TOGGLE_yaxis1.ticks.display) {
                  this.chart.options.scales.TOGGLE_yaxis1.ticks.display = false;
                  this.chart.data.datasets[0].fill = true;
                  this.chart.data.datasets[legendItem.datasetIndex].hidden = true;
                  this.chart.update();
                } else {
                  this.chart.options.scales.TOGGLE_yaxis1.ticks.display = true;
                  this.chart.data.datasets[0].fill = false;
                  this.chart.data.datasets[0].hidden = false;
                  this.chart.options.scales.y.ticks.display = true;
                  this.chart.data.datasets[legendItem.datasetIndex].hidden = false;
                  this.chart.update();
                }
              } else if (y_axis_id === "comparison") {
                if (!this.chart.options.scales.comparison.ticks.display) {
                  this.chart.data.datasets[0].hidden = true;
                  this.chart.data.datasets[1].hidden = true;
                  this.chart.options.scales.y.ticks.display = false;
                  this.chart.options.scales.TOGGLE_yaxis1.ticks.display = false;
                  this.chart.options.scales.comparison.ticks.display = true;
                  this.chart.data.datasets[2].hidden = false;
                  this.chart.data.datasets[3].hidden = false;
                  this.chart.data.datasets[4].hidden = false;
                  this.chart.update();
                } else {
                  this.chart.data.datasets[2].hidden = true;
                  this.chart.data.datasets[3].hidden = true;
                  this.chart.data.datasets[4].hidden = true;
                  this.chart.options.scales.comparison.ticks.display = false;
                  this.chart.options.scales.y.ticks.display = true;
                  this.chart.data.datasets[0].hidden = false;
                  this.chart.data.datasets[0].fill = true;
                  this.chart.update();
                }
              } else if (y_axis_id === "y") {
                if (!this.chart.options.scales.y.ticks.display) {
                  this.chart.data.datasets[0].hidden = false;
                  this.chart.data.datasets[0].fill = true;
                  this.chart.options.scales.y.ticks.display = true;
                  this.chart.data.datasets[1].hidden = true;
                  this.chart.data.datasets[2].hidden = true;
                  this.chart.data.datasets[3].hidden = true;
                  this.chart.data.datasets[4].hidden = true;
                  this.chart.options.scales.TOGGLE_yaxis1.ticks.display = false;
                  this.chart.options.scales.comparison.ticks.display = false;
                  this.chart.update();
                }
              }
            },
          },
          datalabels: {
            display: false,
          },
          tooltip: {
            intersect: false,
          },
        },
        scales: {
          y: {
            ticks: {
              color: "white",
            },
            grid: {
              display: true,
            },
            position: "left",
          },
          TOGGLE_yaxis1: {
            ticks: {
              color: "white",
              display: false,
              callback: function (value) {
                return value + "%";
              },
            },
            grid: {
              display: true,
            },
            position: "right",
          },
          x: {
            ticks: {
              color: "white",
            },
            grid: {
              display: true,
            },
          },
          comparison: {
            ticks: {
              display: false,
              color: "white",
              callback: function (value) {
                return value + "%";
              },
            },
            grid: {
              display: true,
            },
            position: "left",
          },
        },
      },
    });
  }

  async reportTargetConnected() {
    const bitcoinChange = await this.yDataBitcoin(this.xdata);

    if (this.chart) {
      this.chart.destroy();
      this.initChart();
    }
  }

  async reportTargetDisconnected() {
    const bitcoinChange = await this.yDataBitcoin(this.xdata);

    if (this.chart) {
      this.chart.destroy();
      this.initChart();
    }
  }

  get ydata() {
    const element = document.getElementById("y_metrics");
    const ydataAttribute = element.getAttribute("y-data-metrics");
    return JSON.parse(ydataAttribute);
  }

  get xdata() {
    const element = document.getElementById("x_metrics");
    const xdataAttribute = element.getAttribute("x-data-metrics");
    return JSON.parse(xdataAttribute);
  }

  get y1data() {
    const element = document.getElementById("y1_metrics");
    const y1dataAttribute = element.getAttribute("y1-data-metrics");
    return JSON.parse(y1dataAttribute);
  }

  get yDataCompleteNSIL() {
    const element = document.getElementById("y_data_complete");
    const ydataAttribute = element.getAttribute("y-data-metrics");
    return JSON.parse(ydataAttribute);
  }

  async fetchBitcoinPrices() {
    try {
      const element = document.getElementById("btc_data_complete");
      const btcDataAttribute = element.getAttribute("y-data-metrics");
      return JSON.parse(btcDataAttribute);
    } catch (error) {
      if (error.name === "AbortError") {
        console.error("Request was aborted");
        return false; // Indicates that data fetching was aborted.
      } else {
        console.error(error);
        return false; // Indicates that data fetching had an error.
      }
    }
  }

  async nsilPercentageChange(range) {
    const targetRange = -range.length - 1;
    const nsilData = await this.yDataCompleteNSIL.slice(targetRange);
    return this.percentageChange(this.customSliceWithNulls(nsilData, targetRange));
  }

  percentageChange(array) {
    const percentageOfChange = [];
    for (let i = 1; i < array.length; i++) {
      if (array[i] === null || array[i - 1] === null) {
        percentageOfChange.push(null);
      } else {
        percentageOfChange.push(
          parseFloat((((array[i] - array[i - 1]) / array[i - 1]) * 100).toFixed(3))
        );
      }
    }
    return percentageOfChange;
  }

  async yDataBitcoin(range) {
    try {
      const btcData = await this.fetchBitcoinPrices();
      const targetPeriodLength = range.length;
      const desiredLength = -targetPeriodLength - 1;
      const targetPeriodData = btcData.slice(desiredLength);
      let targetPeriodPrices = [];
      targetPeriodData.forEach((price) => {
        targetPeriodPrices.push(price.toFixed(3));
      });
      let customTargetPeriodPrices = this.customSliceWithNulls(targetPeriodPrices, desiredLength);
      return this.percentageChange(customTargetPeriodPrices);
    } catch (error) {
      console.log(error.message);
    }
  }

  customSliceWithNulls(arr, start) {
    let result = [];
    let originalLength = arr.length;

    // Calculate actual start index
    start = start < 0 ? originalLength + start : start;

    // Add nulls for negative indices
    if (start < 0) {
      for (let i = start; i < 0; i++) {
        result.push(null);
      }
      start = 0;
    }

    // Add array elements or null for out-of-bounds indices
    for (let i = start; i < originalLength; i++) {
      if (i < 0 || i >= originalLength) {
        result.push(null);
      } else {
        result.push(arr[i]);
      }
    }

    return result;
  }
}