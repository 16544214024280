// app/javascript/controllers/buy_remote_controller.js

import { Controller } from "@hotwired/stimulus"
import Modal from 'bootstrap/js/dist/modal';

export default class extends Controller {
    static targets = ["buy-remote-modal"]

    connect() {
        this.modal = new Modal(this.element)
        this.modal.show()

        // Add event listener to the modal for the `hidden.bs.modal` event
        this.element.addEventListener('hidden.bs.modal', this.disconnectCurrencyConversion.bind(this))
    }

    hideBeforeRender(event) {
        if (this.isOpen()) {
            event.preventDefault()
            this.element.addEventListener('hidden.bs.modal', event.detail.resume)
            this.modal.hide()
        }
    }

    isOpen() {
        return this.element.classList.contains("show")
    }

    disconnectCurrencyConversion() {
      document.querySelector('#selector').currencyConversion.stop()
    }
}