// app/javascript/controllers/otp_input_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"]

  connect() {
    this.inputTargets.forEach((input, index) => {
      input.addEventListener("input", (event) => {
        let value = event.target.value

        // Move focus to the next input or submit form
        if (value && index < this.inputTargets.length - 1) {
          this.inputTargets[index + 1].focus()
        } else if (index === this.inputTargets.length - 1 && value) {
          this.submitForm()
        }
      })

      // Handle backspace
      input.addEventListener("keydown", (event) => {
        if (event.key === "Backspace" && !input.value && index > 0) {
          event.preventDefault() // Prevent default behavior of keeping the cursor in the current input box
          this.inputTargets[index - 1].focus()
          this.inputTargets[index - 1].select() // Select the content of the previous input box
        }
      })

      // Handle pasting into the first input box
      if (index === 0) {
        input.addEventListener("paste", (event) => {
          event.preventDefault()
          let pastedText = event.clipboardData.getData("text/plain")
          this.handlePasting(pastedText)
        })
      }
    })
  }

  handlePasting(value) {
    let pastedDigits = value.split('').slice(0, this.inputTargets.length)

    this.inputTargets.forEach((input, index) => {
      input.value = pastedDigits[index] || ''
    })

    if (pastedDigits.length === this.inputTargets.length) {
      this.submitForm()
    }
  }

  submitForm() {
    this.element.closest("form").submit()
    this.inputTargets.forEach(input => input.disabled = true)
  }
}
