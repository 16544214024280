// src/controllers/invchart_controller.js
import { Controller } from "@hotwired/stimulus"
import Chart from 'chart.js/auto'
import FactChartDataLabels from 'chartjs-plugin-datalabels'
Chart.register(FactChartDataLabels);

export default class extends Controller {
  static targets = ["percentageGraph"]

   async connect() {
    
    console.log('connected')
    const formattedXDtata= this.customSliceWithNulls(this.xdata,-365)
    console.log(formattedXDtata)
    const nsilInceptionPrice=1
    const nsilFormattedData=await this.formattedData(this.ydataNsil,nsilInceptionPrice);
    const btcPriceOnNsilInceptionDate=38070.26
    console.log(nsilFormattedData)
    const btcFormattedData=await this.formattedData(this.ydataBtc,btcPriceOnNsilInceptionDate);
    console.log(btcFormattedData)
    // this.chart = new Chartkick.LineChart(this.chartTarget, this.data) 
    this.chart = new Chart(this.percentageGraphTarget.getContext('2d'), {
      type: 'line',
      data: {
        labels: formattedXDtata,
        datasets: [
          {
          label: 'NSIL $1000 Investment',
          yAxisID: "y",
          backgroundColor: 'rgb(47, 128, 237)',
          borderColor: 'rgb(47, 128, 237)',
          data: nsilFormattedData,
          order: 1,
          hidden:false,
          fill: false,
          tension: 0.4,
          pointRadius: 0
        },{
          label: 'BTC $1000 Investment',
          yAxisID: "y",
          backgroundColor: "#cc9900",
          borderColor: "#cc9900",
          data: btcFormattedData,
          order: 2,
          hidden:false,
          fill: false,
          tension: 0.4,
          pointRadius: 0
        }],},
    
      plugins: [FactChartDataLabels],
      options: {
        responsive: true,
        maintainAspectRatio: true,
        stacked: true,
        plugins: {
          title: {
            display: false,
            color: 'white'
          },
          legend: {
            display: true,
            position: 'top',
            labels: {
              usePointStyle: false,
              boxWidth: 12,
              boxHeight: 6,
            },
           
          },
          datalabels: {
            display: false
          },
          tooltip: {
            intersect: false
          } ,
        },
        scales: {
          y: {
            ticks: {
              color: 'black',
              display:true
            },
            grid: {
              display: false,
            },
            position: 'left',
          },      
          
        }
      },
    },
  )
 
   
  }


  get ydataNsil() {
    var element = document.getElementById("y_nsil")
    var ydataAttribute = element.getAttribute('y-data-metrics');
    // console.log(JSON.parse(ydataAttribute))
    let ydata = JSON.parse(ydataAttribute)


    return ydata
  }
  get ydataBtc() {
    var element = document.getElementById("y_btc")
    var ydataAttribute = element.getAttribute('y-data-metrics');
    // console.log(JSON.parse(ydataAttribute))
    let ydata = JSON.parse(ydataAttribute)


    return ydata
  }

  get xdata() {
    var element = document.getElementById("x_metrics")
    var xdataAttribute = element.getAttribute('x-data-metrics');
    // console.log(JSON.parse(xdataAttribute))
    let xdata = JSON.parse(xdataAttribute)


    return xdata
  }
  
    async formattedData(asset,asset_price_on_nsil_inception_date){
      const data=await asset
      const percentageChange=this.percentageChange(data,asset_price_on_nsil_inception_date)
      const assetWorth=this.invest1000USD(percentageChange);
      // console.log(assetWorth)
      
      return this.customSliceWithNulls(assetWorth,-365)
    }


    invest1000USD(array){
      let currentWorth=[]
      for(let i=0;i<array.length;i++){
        currentWorth.push(array[i]*1000)
      }
      return currentWorth
    }

  percentageChange(array,asset_price_on_nsil_inception_date) { 
    const percentageOfChange = [];
    for (let i = 1; i < array.length; i++) {
        percentageOfChange.push(parseFloat((array[i]/asset_price_on_nsil_inception_date).toFixed(3)));
    }
    return percentageOfChange;
  }

  customSliceWithNulls(arr, start) {
    let result = [];
    let originalLength = arr.length;

    // Calculate actual start index
    start = start < 0 ? originalLength + start : start;

    // Add nulls for negative indices
    if (start < 0) {
      for (let i = start; i < 0; i++) {
        result.push(null);
      }
      start = 0;
    }

    // Add array elements or null for out-of-bounds indices
    for (let i = start; i < originalLength; i++) {
      if (i < 0 || i >= originalLength) {
        result.push(null);
      } else {
        result.push(arr[i]);
      }
    }

    return result;
  }
}
  
