// app/javascript/controllers/paypal_subscribe_button_controller.js
import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["buttonContainer", "paypalUrl", "planId", "ajaxUrl"]

  connect() {
    console.log('PayPal Subscribe Button Controller connected...');
    this.loadPayPal();
  }

  loadPayPal() {
    const paypalUrl = this.paypalUrlTarget.dataset.url;
    console.log("Attempting to load PayPal SDK from URL:", paypalUrl);
    if (!paypalUrl) {
      console.error("PayPal URL is null or undefined.");
      return;
    }

    const script = document.createElement("script");
    script.src = paypalUrl;
    script.setAttribute("data-sdk-integration-source", "button-factory");
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
    script.onload = () => {
      console.log("PayPal SDK loaded successfully.");
      this.initializePayPalButton();
    };
    script.onerror = () => {
      console.error("Failed to load PayPal SDK.");
    };
  }

  initializePayPalButton() {
    console.log("Initializing PayPal Button...");
    if (typeof paypal === 'undefined') {
      console.warn('PayPal SDK not loaded yet.');
      return;
    }

    if (!this.buttonContainerTarget.querySelector('.paypal-button')) {
      console.log("Rendering PayPal button...");
      paypal.Buttons({
        style: {
          shape: 'rect',
          color: 'gold',
          layout: 'vertical',
          label: 'subscribe'
        },
        createSubscription: (data, actions) => {
          console.log("Creating subscription...");
          return actions.subscription.create({
            plan_id: this.planIdTarget.dataset.planId,
            application_context: {
              shipping_preference: 'NO_SHIPPING',
              brand_name: "Insaaph Capital",
              user_action: "SUBSCRIBE_NOW",
            }
          });
        },
        onApprove: (data, actions) => {
          console.log("Subscription approved:", data);
          this.handleApproval(data);
        },
        onError: (err) => {
          console.error("Error in PayPal Button rendering:", err);
        }
      }).render(this.buttonContainerTarget);
    } else {
      console.log("PayPal button already rendered.");
    }
  }

  handleApproval(data) {
    console.log("Handling approval with data:", data);
    console.log("THIS IS THE SUB ID:", data.subscriptionID);
    let formData = new FormData(); // Create a new FormData object
    formData.append('subscriptionId', data.subscriptionID); // Ensure this key matches what Rails expects
    
    Rails.ajax({
      url: this.ajaxUrlTarget.dataset.ajaxUrl,
      type: 'POST',
      data: formData,
      dataType: 'json', // Specify dataType if expecting JSON response
      contentType: false, // Set to false if sending FormData
      processData: false, // Set to false to send non-processed data
      success: (response) => {
        console.log("Subscription process successful:", response);
        if(response.success) {
          window.location.href = response.redirect_url;
        } else {
          console.error('Error:', response.error);
          window.location.href = response.redirect_url;
        }
      },
      error: (error) => {
        console.error('Error during subscription', error);
      }
    });
  }
}
