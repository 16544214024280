// src/controllers/d10chart_controller.js
import { Controller } from '@hotwired/stimulus'
import Chart from 'chart.js/auto'
import D10ChartDataLabels from 'chartjs-plugin-datalabels'
Chart.register(D10ChartDataLabels);

export default class extends Controller {
  static targets = ["d10chart"]

  connect() {
    // this.chart = new Chartkick.LineChart(this.chartTarget, this.data)   
    
    this.chart = new Chart(this.d10chartTarget.getContext('2d'), {
      type: 'doughnut',
      data: {
        labels: this.xdata,
        datasets: [{
          label: '% Weights',
          backgroundColor: 'rgba(161, 198, 247, 1)',
          borderColor: 'rgb(47, 128, 237)',
          data: this.ydata,
          tooltip: {
            callbacks: {
                label: function(context) {
                    let label = context.label;
                    let value = context.formattedValue;
    
                    if (!label)
                        label = 'Unknown'
    
                    let sum = 0;
                    let dataArr = context.chart.data.datasets[0].data;
                    dataArr.map(data => {
                        sum += Number(data);
                    });
    
                    let percentage = (value * 100 / sum).toFixed(2) + '%';
                    return label + ": " + percentage;
                }
            }
        },
        }]
      },
      plugins: [D10ChartDataLabels],
      options: {
        plugins: {
          title: {
            display: true,
            text: '% of Assets held'
          },
            legend: {
                display: false,
            },
            datalabels: {
              anchor: 'center',
              display: 'auto',
              formatter: function (value, context) {
                return context.chart.data.labels[
                    context.dataIndex
                ];
            },
              // color: '#c37d5d',
            }
        }
      },
    }) 
  }

  

  reportTargetConnected() {
    if (this.chart) {

      this.chart.destroy()
      this.chart = new Chart(this.d10chartTarget.getContext('2d'), {
        type: 'doughnut',
        data: {
          labels: this.xdata,
          datasets: [{
            label: '% Weights',
            backgroundColor: 'rgba(161, 198, 247, 1)',
            borderColor: 'rgb(47, 128, 237)',
            data: this.ydata,
            tooltip: {
              callbacks: {
                  label: function(context) {
                      let label = context.label;
                      let value = context.formattedValue;
      
                      if (!label)
                          label = 'Unknown'
      
                      let sum = 0;
                      let dataArr = context.chart.data.datasets[0].data;
                      dataArr.map(data => {
                          sum += Number(data);
                      });
      
                      let percentage = (value * 100 / sum).toFixed(2) + '%';
                      return label + ": " + percentage;
                  }
              }
          },
          }]
        },
        plugins: [D10ChartDataLabels],
        options: {
          plugins: {
            title: {
              display: true,
              text: '% of Assets held'
            },
              legend: {
                  display: false,
              },
              datalabels: {
                anchor: 'center',
                display: 'auto',
                formatter: function (value, context) {
                  return context.chart.data.labels[
                      context.dataIndex
                  ];
              },
                // color: '#c37d5d',
              }
          }
        },
      })

      this.chart.update()
    }
  }

  reportTargetDisconnected() {
    if (this.chart) {

      this.chart.destroy()
      this.chart = new Chart(this.d10chartTarget.getContext('2d'), {
        type: 'doughnut',
        data: {
          labels: this.xdata,
          datasets: [{
            label: '% Weights',
            backgroundColor: 'rgba(161, 198, 247, 1)',
            borderColor: 'rgb(47, 128, 237)',
            data: this.ydata,
            tooltip: {
              callbacks: {
                  label: function(context) {
                      let label = context.label;
                      let value = context.formattedValue;
      
                      if (!label)
                          label = 'Unknown'
      
                      let sum = 0;
                      let dataArr = context.chart.data.datasets[0].data;
                      dataArr.map(data => {
                          sum += Number(data);
                      });
      
                      let percentage = (value * 100 / sum).toFixed(2) + '%';
                      return label + ": " + percentage;
                  }
              }
          },
          }]
        },
        plugins: [D10ChartDataLabels],
        options: {
          plugins: {
            title: {
              display: true,
              text: '% of Assets held'
            },
              legend: {
                  display: false,
              },
              datalabels: {
                anchor: 'center',
                display: 'auto',
                formatter: function (value, context) {
                  return context.chart.data.labels[
                      context.dataIndex
                  ];
              },
                // color: '#c37d5d',
              }
          }
        },
      })

      this.chart.update()
    }
  }

  get ydata() {
    var element = document.getElementById("d10_y_metrics")
    var ydataAttribute = element.getAttribute('y-data-d10metrics');
    console.log(JSON.parse(ydataAttribute))
    let ydata = JSON.parse(ydataAttribute)

    return ydata
  }

  get xdata() {
    var element = document.getElementById("d10_x_metrics")
    var xdataAttribute = element.getAttribute('x-data-d10metrics');
    console.log(JSON.parse(xdataAttribute))
    let xdata = JSON.parse(xdataAttribute)

    return xdata
  }
}

