// app/javascript/controllers/user_sort_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["checkbox"]
  
    toggleSort(event) {
      const url = new URL(window.location)
      url.searchParams.set("sort_by_created_at", this.checkboxTarget.checked)
  
      // Fetch the updated content without a full page reload
      Turbo.visit(url, { action: "replace" })
    }
  }