
import { BrowserQRCodeReader } from "@zxing/library";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.reader = new BrowserQRCodeReader();
    this.startDecoding = this.startDecoding.bind(this);
    this.closeScanner = this.closeScanner.bind(this);
    this.toastElement = document.querySelector('.toast');
    //This is the toast element that shows when there is no permission for camera
    this.descriptiveToast = document.getElementById('desc')
    this.toast = new bootstrap.Toast(this.toastElement);
    this.descToast = new bootstrap.Toast(this.descriptiveToast);
    this.toastElement.style.display = 'none';
    this.descriptiveToast.style.display = 'none';
  }

  startDecoding() {
    $("#scannerModal").modal("show");

    this.reader
      .decodeFromInputVideoDevice(undefined, "video")
      .then((result) => {
        const scannedAddress = result.text;
        if (this.isValidAddress(scannedAddress)) {
          document.getElementById("inputAddress").value = scannedAddress;
        } else {
          document.getElementById("scannerWarningToast").innerText = "Invalid wallet address";
          this.toastElement.style.display = 'block';
          this.toast.show();
        }

        $("#scannerModal").modal("hide");
        this.reader.reset();
      })
      .catch(
        (err) => {
          (document.getElementById("scannerWarningToast").innerText = err.message);
          if (err.message.includes("Permission denied")) {
            document.getElementById("scannerDescriptiveToast").innerText = "Please allow camera access to scan QR code";
            this.descriptiveToast.style.display = 'block';
            this.descToast.show();


          }

          this.toastElement.style.display = 'block';
          this.toast.show();

          //If you delete this line the modal will have to be manually closed in case of scanning error
          setTimeout(() => $("#scannerModal").modal("hide"), 1500);


        }

      )
      .finally(() => {
        setTimeout(() => this.reader.reset(), 1000);
      });
  }

  closeScanner() {
    $("#scannerModal").modal("hide");
    this.reader.reset();
  }

  isValidAddress(address) {
    const ethereumAddressPattern = /^0x[a-fA-F0-9]{40}$/;
    return ethereumAddressPattern.test(address);
  }
}
