import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["query", "results", "clearButton", "spinner"];

  connect() {
    this.debouncedFind = this.debounce(this.find, 300);
  }

  find() {
    const query = this.queryTarget.value.trim();

    this.clearButtonTarget.style.display = 'none';
    this.spinnerTarget.style.display = 'block';

    if (query.length < 1) {
      this.resultsTarget.innerHTML = "";
      this.resultsTarget.style.display = 'none';
      this.spinnerTarget.style.display = 'none';
      return;
    }

    fetch(`/contents/live_search?q=${encodeURIComponent(query)}`, {
      method: 'GET',
      headers: {
        "Accept": "application/json"
      }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      let resultsHtml = data.map(content => {
        const imageUrl = content.image_url ? `<img id="dropimage" src="${content.image_url}" class="rounded me-3" width="60" height="60">` : '';
        const title = content.title ? `<strong>${this.highlight(content.title, query)}</strong><br>` : '';
        const description = content.description ? `<small>${this.highlight(content.description, query)}</small>` : '';

        return `
          <li><a href="/contents/${content.external_id}" id="sdropditem" class="dropdown-item">
            <div class="d-flex align-items-center">
              ${imageUrl}
              <div>
                ${title}
                ${description}
              </div>
            </div>
          </a></li>
        `;
      }).join('');

      resultsHtml = `<li><h6 class="dropdown-header p-3">MOST RELEVANT RESULTS</h6></li>` + resultsHtml + `<li class="d-flex justify-content-center">
                    <a href="/contents/search_results?q=${encodeURIComponent(query)}" class="dropdown-item w-100 d-flex justify-content-center p-3">
                        View all results&nbsp;&nbsp;<i class="bi bi-arrow-right"></i>
                    </a>
                    </li>`;

      this.resultsTarget.innerHTML = resultsHtml;
      this.resultsTarget.style.display = data.length > 0 ? 'block' : 'none';
      this.clearButtonTarget.style.display = 'block';
      this.spinnerTarget.style.display = 'none';
    })
    .catch(error => {
      console.error('There was a problem with the fetch operation:', error.message);
    });
  }

  clearQuery() {
    this.queryTarget.value = "";
    this.resultsTarget.innerHTML = "";
    this.resultsTarget.style.display = 'none';
    this.clearButtonTarget.style.display = 'none';
  }

  debounce(callback, wait) {
    let timeout;
    return (...args) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => callback.apply(context, args), wait);
    };
  }

  highlight(text, searchTerm) {
    const re = new RegExp(searchTerm, 'gi');
    return text ? text.replace(re, match => `<span class="highlight">${match}</span>`) : '';
  }
}
