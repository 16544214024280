import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["bookmarkId"];
  
  // Bookmarking action
  bookmark(event) {
    // Prevent the default action of the button
    event.preventDefault();
    
    // Stop the propagation of the event so enclosing anchor tag doesn't get triggered
    event.stopPropagation();

    // Get the doc ID from the data-bookmark-id attribute
    const docId = this.bookmarkIdTarget.getAttribute("data-bookmark-id");
    console.log('Document ID:', docId);

    // AJAX call to bookmark the article
    fetch(`/bookmarks/${docId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken() // Get the CSRF token
      }
    })
    .then(response => {
      if(response.ok) {
        return response.json();
      }
      throw new Error('Network response was not ok.');
    })
    .then(data => {
      if(data.status === 'success') {
        const icon = this.bookmarkIdTarget.querySelector(".bi");
        if(data.bookmarked) {
          icon.classList.remove("bi-bookmark");
          icon.classList.add("bi-bookmark-fill");
        } else {
          icon.classList.remove("bi-bookmark-fill");
          icon.classList.add("bi-bookmark");
        }
      }
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }

  // Helper method to get the CSRF token from meta tags
  csrfToken() {
    const element = document.querySelector("meta[name='csrf-token']");
    return element.getAttribute("content");
  }
}
