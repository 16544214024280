// app/javascript/controllers/currency_conversion_controller.js

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["usdTab", "btcTab", "tokenUsdConvertInput", "tokenUsdConvertOutput", "usdUsdConvertOutput", "btcBtcConvertInput", "usdBtcConvertOutput", "btcBtcConvertOutput", "usd2BtcConvertOutput", "maxusdButton", "maxbtcButton", "tokenPrice", "btcPrice" ];

  connect() {
    console.log('Connected');

    this.initializePrices();

    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
    ] = this

    // Set abort
    this.cancelRequest = new AbortController();


    // Fetch prices immediately on connect
    this.fetchPricesInterval = setInterval(() => {
      this.fetchPrices(); 
    }, 5000);
    
    // Add event listeners to tab elements
    this.usdTabTarget.addEventListener("click", this.usdConvert.bind(this));
    this.btcTabTarget.addEventListener("click", this.btcConvert.bind(this));

    // Add event listeners to max buttons
    this.maxusdButtonTarget.addEventListener("click", this.setMaxUsdValue.bind(this));
    this.maxbtcButtonTarget.addEventListener("click", this.setMaxBtcValue.bind(this));
  }

  initializePrices() {
    if (this.data.has("tokenPrice")) {
      this.tokenPrice = parseFloat(this.data.get("tokenPrice"));
    }

    if (this.data.has("btcPrice")) {
      this.btcPrice = parseFloat(this.data.get("btcPrice"));
    }
  }

  async fetchPrices() {
    try {
        const response = await fetch("/prices", {
            signal: this.cancelRequest.signal,
        });

        // Check if response was successful
        if (!response.ok) {
            console.error(`Fetch failed with status: ${response.status}`);
            return false;
        }

        const data = await response.json();

        this.tokenPrice = parseFloat(data[0]);
        this.btcPrice = parseFloat(data[1]);
        // console.log('Data --> ' + this.tokenPrice + ' & ' + this.btcPrice)
        this.usdConvert();
        this.btcConvert();

        return true; // Indicates that data fetching was successful.

    } catch (error) {
        if (error.name === "AbortError") {
            console.error("Request was aborted");
            return false; // Indicates that data fetching was aborted.
        } else {
            console.error(error);
            return false; // Indicates that data fetching had an error.
        }
    }
  }


  usdConvert() {
    const tokenUsdValue = parseFloat(this.tokenUsdConvertInputTarget.value); // Default to 0 if NaN // Get USD input value
  
    if (!this.tokenPrice) return; // Avoid operation if tokenPrice is not set
  
    const tokenUnitValue = tokenUsdValue / this.tokenPrice; // Calculate USD input value in NSIL
  
    this.tokenUsdConvertOutputTarget.innerText = tokenUnitValue.toFixed(8); // Display, ~ NSIL x.xxxxxxxx (for USD Input)
    this.usdUsdConvertOutputTarget.innerText = this.tokenPrice.toFixed(2); // Display, at USD x.xx per token
  }

  btcConvert() {
    const btcBtcValue = parseFloat(this.btcBtcConvertInputTarget.value); // Default to 0 if NaN // Get BTC input value
  
    if (!this.btcPrice || !this.tokenPrice) return; // Avoid operation if any price is not set
  
    const btcUsdValue = btcBtcValue * this.btcPrice; // Calculate BTC value in USD 
    const tokenBtcValue = this.tokenPrice / this.btcPrice; // Calculate NSIL value in BTC
  
    this.usdBtcConvertOutputTarget.innerText = btcUsdValue.toFixed(2); // Display, ~ USD x.xx (for BTC Input)
    this.btcBtcConvertOutputTarget.innerText = tokenBtcValue.toFixed(8); // Display, at BTC x.xxxxxxxx per token
    this.usd2BtcConvertOutputTarget.innerText = this.tokenPrice.toFixed(2); // Display, ~ USD x.xx (for Token Price)
  }
  

  setMaxUsdValue() {
    const maxVal = parseFloat(this.tokenUsdConvertInputTarget.getAttribute("max"));
    this.tokenUsdConvertInputTarget.value = maxVal;
    this.tokenUsdConvertInputTarget.dispatchEvent(new Event("input"));
  }

  setMaxBtcValue() {
    const maxVal = parseFloat(this.btcBtcConvertInputTarget.getAttribute("max"));
    this.btcBtcConvertInputTarget.value = maxVal;
    this.btcBtcConvertInputTarget.dispatchEvent(new Event("input"));
  }

  stop () {
    this.cancelRequest.abort();
    clearInterval(this.fetchPricesInterval); // Clear the interval
    this.disconnect();
  }

  disconnect() {
    console.log('Disconnected');
    clearInterval(this.fetchPricesInterval); // Ensure the interval is cleared when the controller disconnects
  }

}
