import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="turnstile"
export default class extends Controller {
  connect() {
    this.loadTurnstile()
  }

  loadTurnstile() {
    if (typeof window.turnstile === 'undefined') {
      const script = document.createElement('script')
      script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js'
      script.async = true
      script.defer = true
      script.onload = () => this.renderTurnstile()
      document.head.appendChild(script)
    } else {
      this.renderTurnstile()
    }
  }

  renderTurnstile() {
    window.turnstile.render(this.element.querySelector('.cf-turnstile'), {
      sitekey: this.data.get('siteKey')
    })
  }
}
