// app/javascript/controllers/sell_currency_conversion_controller.js

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["usdTab", "btcTab", "maxusdButton", "maxbtcButton", "tokenPrice", "btcPrice", "sellTokenUnitConvertInput", "sellTokenUsdConvertOutput", "sellUsdUsdConvertOutput" ];

  connect() {
    console.log('Connected');

    this.sellTokenUnitConvertInputTarget.addEventListener("input", this.handleInputPrecision.bind(this));
    this.initializePrices();

    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
    ] = this

    // Set abort
    this.cancelRequest = new AbortController();


    // Fetch prices immediately on connect
    this.fetchPricesInterval = setInterval(() => {
      this.fetchPrices(); 
    }, 5000);
    
    // Add event listeners to tab elements
    this.usdTabTarget.addEventListener("click", this.usdConvert.bind(this));
    // this.btcTabTarget.addEventListener("click", this.btcConvert.bind(this));

    // Add event listeners to max buttons
    this.maxusdButtonTarget.addEventListener("click", this.setMaxUsdValue.bind(this));
    // this.maxbtcButtonTarget.addEventListener("click", this.setMaxBtcValue.bind(this));

    
  }

  handleInputPrecision(event) {
    const inputElement = event.target;
    // Allow only numbers and a single decimal point.
    inputElement.value = inputElement.value.replace(/[^0-9\.]/g, '');

    // Limit to 18 digits in total, including decimals
    const match = inputElement.value.match(/^(\d{0,18}(\.\d{0,18})?)?/);
    inputElement.value = match ? match[0] : "";
  }


  initializePrices() {
    if (this.data.has("tokenPrice")) {
      this.tokenPrice = parseFloat(this.data.get("tokenPrice"));
    }

    if (this.data.has("btcPrice")) {
      this.btcPrice = parseFloat(this.data.get("btcPrice"));
    }
  }

  async fetchPrices() {
    try {
        const response = await fetch("/prices", {
            signal: this.cancelRequest.signal,
        });

        // Check if response was successful
        if (!response.ok) {
            console.error(`Fetch failed with status: ${response.status}`);
            return false;
        }

        const data = await response.json();

        this.tokenPrice = parseFloat(data[0]);
        this.btcPrice = parseFloat(data[1]);
        // console.log('Data --> ' + this.tokenPrice + ' & ' + this.btcPrice)
        this.usdConvert();
        // this.btcConvert();

        return true; // Indicates that data fetching was successful.

    } catch (error) {
        if (error.name === "AbortError") {
            console.error("Request was aborted");
            return false; // Indicates that data fetching was aborted.
        } else {
            console.error(error);
            return false; // Indicates that data fetching had an error.
        }
    }
  }

  // Conversions when USD tab selected
  usdConvert() {
    const sellTokenUnitValue = parseFloat(this.sellTokenUnitConvertInputTarget.value); // Default to 0 if NaN // Get USD input value

    if (!this.tokenPrice) return; // Avoid operation if tokenPrice is not set
  
    const sellTokenUsdValue = sellTokenUnitValue * this.tokenPrice; // Calculate NSIL input value in USD
  
    this.sellTokenUsdConvertOutputTarget.innerText = sellTokenUsdValue.toFixed(2); // Display, at USD x.xx per token (for NSIL Input)
    this.sellUsdUsdConvertOutputTarget.innerText = this.tokenPrice.toFixed(2); // Display, at USD x.xx per token
  } 

  // Conversions when BTC tab selected
  // btcConvert() {

  // }   

  setMaxUsdValue() {
    const maxVal = this.sellTokenUnitConvertInputTarget.getAttribute("max");
    this.sellTokenUnitConvertInputTarget.value = maxVal;
    this.sellTokenUnitConvertInputTarget.dispatchEvent(new Event("input"));
  }

  // setMaxBtcValue() {
  //   const maxVal = parseFloat(this.btcBtcConvertInputTarget.getAttribute("max"));
  //   this.btcBtcConvertInputTarget.value = maxVal;
  //   this.btcBtcConvertInputTarget.dispatchEvent(new Event("input"));
  // }

  stop () {
    this.cancelRequest.abort();
    clearInterval(this.fetchPricesInterval); // Clear the interval
    this.disconnect();
  }

  disconnect() {
    console.log('Disconnected');
    clearInterval(this.fetchPricesInterval); // Ensure the interval is cleared when the controller disconnects
  }

}
