// src/controllers/c10chart_controller.js
import { Controller } from '@hotwired/stimulus'
import Chart from 'chart.js/auto'
import C10ChartDataLabels from 'chartjs-plugin-datalabels'
Chart.register(C10ChartDataLabels);

export default class extends Controller {
  static targets = ["c10graph"]

  connect() {
    const xdata = this.xdata;
    const ydata = this.ydata;
    const data=[];
    for (let i = 0; i < xdata.length; i++) {
      data.push({ x: xdata[i], y: ydata[i] });
    }
    
    function sortByYValue(array) {
      return array.sort((a, b) => b.y - a.y);
  }
  const sortedData = sortByYValue(data);
  const sortedXData = sortedData.map((item) => item.x);
  const sortedYData = sortedData.map((item) => item.y);
  // console.log(sortByYValue(data))
    this.chart = new Chart(this.c10graphTarget.getContext('2d'), {
      type: 'doughnut',
      data: {
        labels: sortedXData,
        datasets: [{
          label: '% Weights',
          backgroundColor: this.colors,
          borderColor: 'rgba(47, 128, 237, 0.1)',
          data: sortedYData,
        }]
      },
      plugins: [C10ChartDataLabels],
      options: {
        responsive: true,
        maintainAspectRatio: true,
        stacked: false,
        plugins: {
          title: {
            display: false,
          },
          legend: {
            display: true,
            position: 'right',
            labels: {
              fontColor: '#333',
              font: {
                size: 14 // Adjust font size of legend labels
              },
              boxWidth: 48, // Customize width of legend box
              boxHeight: 16, // Customize width of legend box
              padding: 4,  // Customize padding around text
              usePointStyle: false, // Use point style instead of rectangles
              generateLabels: (chart) => {
                const data = chart.data;
                if (data.labels.length && data.datasets.length) {
                  
                  
                  return data.labels.map((label, i) => {
                    const value = data.datasets[0].data[i];
                    
                    return {
                      text: `${label}: ${value}%`,
                      fillStyle: data.datasets[0].backgroundColor[i],
                      strokeStyle: 'rgba(0,0,0,0)',
                      index: i
                    };
                  });
                }
                return [];
              }
            }
          },
          datalabels: {
            anchor: 'center',
            display: 'auto',
            formatter: function (value, context) {
              return context.chart.data.labels[context.dataIndex];
            },
            color: '#ffffff',
          }
        }
      },
    });
  }

  get ydata() {
    const element = document.getElementById("c10_y_metrics");
    const ydataAttribute = element.getAttribute('y-data-c10metrics');
    return JSON.parse(ydataAttribute);
  }

  get xdata() {
    const element = document.getElementById("c10_x_metrics");
    const xdataAttribute = element.getAttribute('x-data-c10metrics');
    return JSON.parse(xdataAttribute);
  }

  get colors() {
    
    const colorsPalette=[
      "#264653", "#2A9D8F", "#F77F00", "#D62828", "#FCBF49",
      "#003459", "#007EA7", "#00A8E8", "#ffba08", "#F4A261",
      "#E76F51", "#A8DADC", "#457B9D", "#1D3557", "#2B2D42",
      "#8D99AE", "#F77F00", "#F1FAEE", "#F94144", "#90BE6D"
    ]
    
    return colorsPalette;
  }
}
