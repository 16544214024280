// src/controllers/clipboard_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "source" ]
  
  copy (event) {
    event.preventDefault()

    let text = this.sourceTarget.value

    navigator.clipboard.writeText(text).then(()=> {
      this.copied
    });
  }
}

